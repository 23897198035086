import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import config from "../utils/siteConfig";
import Layout from "../components/Layout/Layout";
import PageBody from "../components/Layout/PageBody";
import SEO from "../components/SEO";
import { Hero } from "../components/ContentModules";

const PageTemplate = ({ data, location, pageContext }) => {
  const { title, body } = data.contentfulPage;
  const { slug, seo, pagePath } = pageContext;
  const postNode = data.contentfulPage;

  return (
    <Layout location={location} slug={slug}>
      <Helmet>
        <title>{`${title} - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO
        {...seo}
        slug={slug}
        pagePath={pagePath}
        postNode={postNode}
        pageSEO
      />
      <Hero
        module={{
          layout: "simple-centered",
          mainElement: { title: title, type: "title" },
          noAnimation: true,
        }}
      />
      <div className="container">
        <PageBody body={body} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!) {
    contentfulPage(id: { eq: $id }) {
      title
      metaDescription {
        internal {
          content
        }
      }
      body {
        raw
      }
    }
  }
`;

export default PageTemplate;
